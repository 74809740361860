h1,
h2,
h3,
h4 {
  color: #817f7d;
}
tr.attr-not-editable > td {
  color: #999;
}
/* small inline forms, such as team admin new field form */
.form-group.sm-il-form {
  margin-right: 10px;
}
span.sblogo {
  background-color: #dd4814;
  padding: 7px;
  border-radius: 4px;
  color: white;
}
span.sblogo i {
  color: #FDC9C9;
  margin: 0 1px;
}
.modal-content {
  padding: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb40 {
  margin-bottom: 40px;
}
.mt30 {
  margin-top: 30px;
}
div.help table {
  border-collapse: true;
  border: 1px solid darkgray;
}
div.help th,
div.help td {
  border: 1px solid darkgray;
  padding: 3px;
}
nav#actions-bar {
  position: fixed;
  top: 51px;
  right: 0;
  left: 0;
  z-index: 800;
}
nav#actions-bar div#actions-bar-content {
  background-color: #f5f5f5;
  padding: 3px 5px;
  -webkit-box-shadow: 0px 2px 2px 0px #c3c3c3;
  -moz-box-shadow: 0px 2px 2px 0px #c3c3c3;
  box-shadow: 0px 2px 2px 0px #c3c3c3;
}
/*
 * Off Canvas
 * --------------------------------------------------
 */
span.inc-header-fact {
  font-size: 1.3em;
}
@media print {
  .tab-content > .tab-pane {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}
div.tm-item {
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  padding: 3px;
  margin-bottom: 2px;
  background-color: #f5f5f5;
  cursor: pointer;
}
div.tm-item i.icon-tick {
  visibility: hidden;
}
div.tm-item i.icon-add {
  visibility: visible;
}
div.tm-item:hover {
  background-color: #ffffff;
}
div.tm-item.selected {
  border: 1px solid #95d787;
  background-color: #dfffcd;
}
div.tm-item.selected i.icon-tick {
  visibility: visible;
}
div.tm-item.selected i.icon-add {
  visibility: hidden;
}
iframe.help-aside-iframe {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 93%;
}
/* hidden text in textareas when disabled (private encrypted data) */
textarea.hidden-disabled {
  background-color: #ffffff !important;
  color: #ffffff;
}
button.reveal-private {
  position: relative;
  top: -13rem;
  left: 20rem;
}
/* dropdown submenus */
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
/**
 editor
 */
span.del-prepend {
  position: absolute;
  left: -20px;
  top: 10px;
}
span.del-prepend.first-del-prepend {
  top: 32px;
}
/** flashing icons */
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blinky {
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;
}
/* -- end flashing icons */
/**
 *
 * Reports
 *
 */
table.table-incview td.lab {
  font-size: 0.8em;
  text-align: right;
  vertical-align: middle;
}
table.table-incview td.val {
  font-weight: bold;
  vertical-align: middle;
}
table.table-incview span.dloop {
  font-weight: normal;
  font-size: 0.8em;
}
/* forms */
div.form-group.has-error .form-control {
  border-width: 2px;
}
div.form-group.has-error label.help-block {
  position: absolute;
  left: 30px;
  top: 3px;
}
nav.subform-menu {
  min-height: 20px;
  /*
 * hide a section when not-affixed, (overidden when affixed)
 */
}
nav.subform-menu .navbar-brand {
  height: 20px;
}
nav.subform-menu .affix-hide-block {
  display: none;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
nav.subform-menu.affix {
  top: 51px;
  width: 100%;
  z-index: 999 !important;
  animation: fadeIn 1s ease-in;
  -webkit-animation: fadeIn 1s;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-left: 2px solid #de4815;
  border-right: 2px solid #de4815;
  border-bottom: 2px solid #de4815;
  box-shadow: #999 0px 1px 10px 0px;
}
nav.subform-menu.affix .affix-hide-block {
  display: block;
}
nav.subform-menu.affix + .row {
  padding-top: 50px;
}
/* LHS subject menu - active item has a different text color in icon */
div#menu-subjects a.list-group-item.active i.fa {
  color: white;
}
i.req-warn.onlabel {
  position: relative;
  float: right;
  font-size: small;
  top: 38px;
  left: 4px;
  z-index: 5;
}
i.req-warn.onselect {
  float: right;
  position: relative;
  top: 17px;
  left: 6px;
  font-size: small;
}
