.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.label.tag {
    margin-right: 0.8em;
    background-color: #ecf1f2;
    color: #3d434e;
    font-weight: bold;
    font-size: 80%;
    line-height: 1.5em;
    border: 1px solid #abb6b8;
    display: inline-block;
    margin-bottom: 0.8em;
}
.label.tag [data-role="action"] {
    margin-left: 8px;
    cursor: pointer;
    border-left: 1px solid #d1d1d1;
    padding-left: 0.5em;
    color: #387d07;
}
.label.tag [data-role="action"].remove {
  color: #b94a48;
}
.label.tag [data-role="action"]:hover {
  color: #88ee3f;
}
.label.tag [data-role="action"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}