/* from https://getbootstrap.com/docs/3.3/examples/offcanvas/offcanvas.css */
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

div#main-col {
	min-height: 1850px; 
	background-color: #FFFFFF;
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div#main-container {
  background-color: #FEFEFE;
  padding-top: 70px; /* navbar is stuck to top */
}

a {
	-khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

/** HELP sidebar */
div#show-help-sidebar {
  position: fixed;
  top: 55px;
  right: 18px;
  z-index: 2;
}

div.tab-content {
	padding: 20px;
}
 